<script lang="ts">
	import { getTranslate } from '@tolgee/svelte';

	// TODO: Fix typings if easily possible - is this provided by storyblok somewhere?
	export let content;
	export let defaults;

	const { t } = getTranslate();
</script>

<svelte:head>
	{#if content?.meta || defaults}
		{@const titleData =
			content?.meta?.find((blok) => blok.component === 'title')?.title || defaults?.title}
		{@const descriptionData =
			content?.meta?.find((blok) => blok.component === 'description')?.description ||
			defaults?.description}
		{@const metaKeywordsData =
			content?.meta?.find((blok) => blok.component === 'meta_keywords')?.meta_keywords ||
			defaults?.meta_keywords}
		{@const should_not_indexData = content?.meta?.find(
			(blok) => blok.component === 'should_not_index'
		)?.should_not_index}

		{#if titleData}
			{#if titleData !== defaults?.title}
				<title>{titleData}</title>
			{:else}
				<title>{$t(titleData)}</title>
			{/if}
		{/if}

		{#if descriptionData}
			{#if descriptionData !== defaults?.description}
				<meta name="description" content={descriptionData} />
			{:else}
				<meta name="description" content={$t(descriptionData)} />
			{/if}
		{/if}

		{#if metaKeywordsData}
			{#if metaKeywordsData !== defaults?.meta_keywords}
				<meta name="keywords" content={metaKeywordsData} />
			{:else}
				<meta name="keywords" content={$t(metaKeywordsData)} />
			{/if}
		{/if}

		{#if should_not_indexData}
			<meta name="robots" content="noindex" />
		{/if}
	{/if}
</svelte:head>
